.MuiButton-root {
    background-color: green;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
  }
  .MuiButton-root:hover {
    background-color: green;
  }
  .MuiButton-label {
    color: #fff;
  }
  .css-1q04gal-MuiDateCalendar-root {
    background-color: yellow;
    color:red;
  }
  .css-o0cc1y-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected {
    background-color: yellow;
    color: green;
}
.css-o0cc1y-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected:focus-visible, .css-o0cc1y-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected:hover {
    background-color: blue;
}