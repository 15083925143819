.dropzone-text {
    font-size: 100px !important; /* or something */
}
.MuiDropzoneArea-root {
    width: 100%;
    border: dashed;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    min-height: 250px;
    border-color: rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    /* background-color: #ad2128 !important; */
}
.MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
    /* background-color: rgb(174, 159, 159) !important; */
}
.MuiButton-label {
    color: #3f51b5 !important;
}
.MuiButton-textPrimary {
    color: #3f51b5 !important;
}
.MuiButton-root {
    color: rgba(0, 0, 0, 0.87) !important;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}